exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-campaigns-index-jsx": () => import("./../../../src/pages/campaigns/index.jsx" /* webpackChunkName: "component---src-pages-campaigns-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-franchise-index-jsx": () => import("./../../../src/pages/franchise/index.jsx" /* webpackChunkName: "component---src-pages-franchise-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-resources-index-jsx": () => import("./../../../src/pages/resources/index.jsx" /* webpackChunkName: "component---src-pages-resources-index-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-terms-and-conditions-index-jsx": () => import("./../../../src/pages/terms-and-conditions/index.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-jsx" */)
}

